import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import theme from 'styles/theme';
import './index.css';
import App from './App';

// const fs = require('fs');
// const { createFFmpeg, fetchFile } = require('@ffmpeg/ffmpeg');
// window.ffmpeg = createFFmpeg({ log: true });
// window.fetchFile = fetchFile;
//
// if (!window.ffmpeg.isLoaded()) {
//   window.ffmpeg.load();
// }
ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
