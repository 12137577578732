import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
/* eslint-disable no-undef */

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff',
  },
}));

export default function Load({isOpen,isNumber,number
}) {
  const classes = useStyles();


  return (
    <div>
      <Backdrop className={classes.backdrop} open={isOpen}>
        <CircularProgress color="inherit" />
        {isNumber && (
          <p>{number}</p>
        )}
      </Backdrop>
    </div>
  );
}

Load.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isNumber:PropTypes.bool,
  number:PropTypes.string
  // isNumber: PropTypes.bool.isRequired,
  // number: PropTypes.int.isRequired,
};

Load.defaultProps = {
  isNumber: false,
  number: '1/1',
};
