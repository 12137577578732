import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Checkbox, Typography,FormControlLabel,Button} from '@material-ui/core';
import LoadMoreButton from '../loadmoreButton';
import UploadButton from './uploadButton';
import AssetGrid from './assetGrid';
import LoadMoreMgr from '../loadmore.util';
import { deleteAsset } from 'modules/campaign/campaign.services';
import {object} from "prop-types";
import {emitter, EMITTER_KEY, load, toast} from "../../../util";

const useStyles = makeStyles({
  assetsGrid: {
    maxHeight: '100%',
    padding: '20px',
    position: 'relative',
  },
  mediaUpload: {
    margin: '10px auto',
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  assetDesc: {
    marginBottom: '10px',
  },
  checkboxBox:{
    position:'absolute',
    top:'6rem',

  }
});

const assetsLoadMoreMgr = new LoadMoreMgr([], { pageSize: 36 });

const AssetsGroup = ({ group, campaignId }) => {
  const classes = useStyles();
  let [checked, setChecked] = useState(false);
  let [assets, setAssets] = useState({ data: [] });

  useEffect(() => {
    //console.log(group.assets)
    group.assets.forEach((item)=>{

        item.checked =false;


    })
    assetsLoadMoreMgr.setData(group.assets);
    setAssets(assetsLoadMoreMgr.first());
  }, [group]);
  const changeReverse = (e)=>{
    if(assets.data){

      assets.data.forEach((item)=>{
        if(item.checked){
          item.checked =false;
        }else{
          item.checked =true;
        }

      })

      setAssets({...assets});
      //this.setData({assets:assets})
    }
  };
  const changeAll = (e)=>{

    if(assets.data){
      let selectNumber = 0;
      assets.data.forEach((item)=>{
        if(item.checked ){
          selectNumber ++;
        }

      })

      assets.data.forEach((item)=>{
        if(selectNumber == assets.data.length){
          item.checked =false;
        }else{
          item.checked =true;
        }
      })


      if(selectNumber == assets.data.length){
        setChecked(false)

      }else{
        setChecked(true)
      }


      //console.log(assets)
      setAssets({...assets});


      //this.setData({assets:assets})
    }

  };
  const  showLog = (index,checked)=>{
    console.log(assets.data[index])
   assets.data[index].checked = checked;
    setAssets({...assets});
    console.log(assets.data[index].checked);
  };
  const deleteAll =  async ()=>{
    load.open();
    let newAssets = { data: [] };
    let deleteNumber = 0;
    //  assets.data.forEach(  async (item,key)=> {
    //   if(item.checked ){
    //
    //     //console.log()
    //     await deleteAsset(item.key);
    //     deleteNumber ++;
    //     //await delete assets.data[key];
    //     //await assets.data.splice(key,1);
    //
    //   }else{
    //     await newAssets.data.push(item)
    //   }
    // })

    for(let i=0;i<assets.data.length;i++ ){
      if(assets.data[i].checked ){

        await deleteAsset(assets.data[i].key);

      }else{
        await newAssets.data.push(assets.data[i])
      }
    }
    console.log(newAssets)
    await setAssets({...newAssets});

      await load.close();
      await toast.success('Delete success.');
      await emitter.emit(EMITTER_KEY.DELETE_ASSET_DONE);


  };
  return (
    <div className={classes.assetsGrid}>
      <div className={classes.checkboxBox}>
      <FormControlLabel　label="Select all" control={<Checkbox checked={checked} color="primary" onChange={changeAll} />}></FormControlLabel>
        <Button onClick={changeReverse} >Reverse</Button>
        <Button onClick={deleteAll} >Delete</Button>
      {/*<FormControlLabel　label="Reverse"  ></FormControlLabel>*/}
      {/*  <FormControlLabel　label="Delete" ></FormControlLabel>*/}

      </div>
      <div className={classes.mediaUpload}>

        <Typography variant="body2" className={classes.assetDesc}>
          Campaign Assets Description: Serious campaign shots involves Model shots,
          stage shots from the campaign.
          Recommend number of assets to be featured says here.
        </Typography>
        <UploadButton
          id={campaignId}
          assetGroupName={group.groupName}
        />
      </div>
      <AssetGrid parent={ showLog }  assets={assets.data} />
      {assets.hasNext && (
        <LoadMoreButton
          variant="text"
          color="primary"
          onClick={() => { setAssets(assetsLoadMoreMgr.next()); }}
        >
          load more
        </LoadMoreButton>
      )}
    </div>
  );
};

export default AssetsGroup;
