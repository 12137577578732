export const MIN_APP_WIDTH = '1024px';
export const MAX_APP_WIDTH = '1500px';

export const LOGIN_STATE = 'GET_CODE';

export const ROUTE_PATH = {
  LOGIN: '/login',
  CAMPAIGNS: '/campaigns',
  CAMPAIGN_DETAIL: '/campaign/:id',
};

export const UPLOAD = {
  LIMIT_SIZE: 1024 * 1024 *100, // 1MB 100MB
  LIMIT_COUNT: 50,
};

export const REQUEST_WEB_PORTAL_ACCESS_EMAIL = process.env.REQUEST_WEB_PORTAL_ACCESS_EMAIL || 'jojo.xu@burberry.com';
