import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardMedia,
  GridList,
  GridListTile,
  Typography,
  Checkbox,
} from '@material-ui/core';

import AssetPreviewLightBox from './assetPreviewLightBox';
import { emitter, EMITTER_KEY } from '../../../util';

const useStyles = makeStyles({
  card: {
    position: 'relative',
    borderRadius: 0,
  },
  cardMediaStyle: {
    height: 100,
    border: 'none',
    shadow: 'none',
    backgroundColor: '#D3C4C4',
  },
  duration: {
    position: 'absolute',
    right: 10,
    bottom: 0,
    color: '#fff',
  },
});

const AssetGrid = ({ assets,parent=null }) => {
  let _this = this;
  const classes = useStyles();

  const [previewConfig, setPreviewConfig] = useState({
    open: false,
    current: 0,
  });

  const preview = (event) => {
    const current = event.currentTarget.dataset.index * 1;
    setPreviewConfig({ open: true, current });
  };

  const closePreview = (deletedStatus) => {
    if (deletedStatus) {
      emitter.emit(EMITTER_KEY.DELETE_ASSET_DONE);
    }
    setPreviewConfig({ open: false });
  };

  const checkThis=(e)=>{
    //e.checked = !e.checked;
    //e.stopPropagation();
    //console.log(e)
    //console.log(checked);
    //console.log(e)
    // console.log(e.target)
    // console.log(newValue)
    // console.log(e.target.getAttribute('data-index'))

    //parent(index,!checked);

    return false;
  };
  const onTabCheckBox=(e)=>{
    //let checked = !e.target.checked;
    //console.log(e.target.checked)
    //checked = e.target.valueOf();getAttribute('checked');

    //console.log(newValue)
    let index = e.target.parentElement.parentElement.getAttribute('data-index');
    parent(index,e.target.checked);
    //console.log(index + "---" +checked);
   //console.log(222)

    e.stopPropagation();
  };
  return (

    <>
      {/*<div>{assets.counts}</div>*/}
      <GridList cellHeight={100} cols={12} spacing={3}>
        {assets.length > 0 && assets.map((asset, idx) => (
          <GridListTile key={asset.id} cols={asset.cols || 1} data-index={idx} onClick={preview}>

            <Card className={classes.card}>

              {/*<div>aaaa-{asset.checked}-bbbb</div>*/}
              <Checkbox color="primary" checked={asset.checked} data-index={idx} onClick={onTabCheckBox} inputProps={{ 'aria-label': 'disabled checked checkbox' }} onChange={checkThis} />
              <CardMedia
                className={classes.cardMediaStyle}
                image={asset.materialThumbUrl}
              />
              {asset.duration && (
                <Typography variant="caption" className={classes.duration}>{asset.duration}</Typography>
              )}
            </Card>
          </GridListTile>
        ))}
      </GridList>
      {previewConfig.open && (
        <AssetPreviewLightBox
          showResourceCount
          data={assets}
          onClose={closePreview}
          startIndex={previewConfig.current}
        />
      )}
    </>
  );
};

export default AssetGrid;
