/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line object-curly-newline
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Divider,
  Tab,
  Typography,
} from '@material-ui/core';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { useParams } from 'react-router-dom';
import {
  getCampaignById,
  updateCampaignTitle,
  updateCampaignCoverImage,
} from '../campaign.services';
import CampaignInfoField from './campaignInfoField';
import AssetGroup from './assetsGroup';
import setTabStyles from '../tabStyles';
import {
  load,
  emitter,
  EMITTER_KEY,
  toast,
} from '../../../util';

const CAMPAIGN_ASSETS = 'Campaign-Assets';
const ASSET_GROUP = {
  [CAMPAIGN_ASSETS]: 'Campaign Assets',
  'PR-Assets': 'PR Assets',
};

const useStyles = makeStyles({
  ...setTabStyles('282px'),
  campaignDetail: {
    flex: 1,
    margin: 0,
    padding: 0,
    maxHeight: '100%',
    overflowY: 'auto',
  },
  campaignInfo: {
    backgroundColor: '#FFFFFF',
    borderRadius: '5px',
    width: '70%',
    height: '80px',
    padding: '0 20px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  campaignTitle: {
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  titleInput: {
    marginLeft: '20px',
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  titleText: {
    disableUnderline: true,
    color: '#000000',
  },
  titleActions: {
    height: '100%',
    marginLeft: '10px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  editButtonText: {
    textDecoration: 'underline',
  },
  buttonGroup: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  campaignCover: {
    flex: 1,
  },
  campaignInfoDivider: {
    margin: '20px',
    height: '60px',
  },
  contentTitle: {
    marginTop: '20px',
    paddingBottom: '15px',
    height: '30px',
  },
});

export default function MainContent() {
  const classes = useStyles();
  const originCampaign = useRef();
  const { id: campaignId } = useParams();
  const [campaignDetail, setCampaignDetail] = useState({});
  const [tabValue, setTabValue] = useState(CAMPAIGN_ASSETS);

  const refresh = useCallback(async (event = {}) => {
    load.open();
    const campaign = await getCampaignById(campaignId);
    originCampaign.current = campaign;
    setCampaignDetail(campaign);
    emitter.emit(EMITTER_KEY.DETAIL_REFRESH_DONE, campaign);
    if (event.returnMsg && !event.success) toast.error(event.returnMsg);
    else if (event.success) toast.success(event.returnMsg);
    load.close();
  }, [campaignId]);

  const loadOpen = () => {
    load.open();
  };

  useEffect(() => {
    emitter.addListener(EMITTER_KEY.UPDATE_ASSET_STATUS_START, loadOpen);
    emitter.addListener(EMITTER_KEY.UPDATE_ASSET_STATUS_DONE, refresh);
    emitter.addListener(EMITTER_KEY.DELETE_ASSET_DONE, refresh);
    emitter.addListener(EMITTER_KEY.UPLOAD_END, refresh);
    (async () => { await refresh(); })();

    return () => {
      emitter.removeListener(EMITTER_KEY.UPDATE_ASSET_STATUS_START, loadOpen);
      emitter.removeListener(EMITTER_KEY.UPDATE_ASSET_STATUS_DONE, refresh);
      emitter.removeListener(EMITTER_KEY.DELETE_ASSET_DONE, refresh);
      emitter.removeListener(EMITTER_KEY.UPLOAD_END, refresh);
    };
  }, [campaignId]);

  const handleTabSwitch = (event, newValue) => {
    setTabValue(newValue);
  };

  const onSaveCoverImage = async ({ id, coverImage }) => {
    const res = await updateCampaignCoverImage(id, coverImage);
    setCampaignDetail({ ...campaignDetail, coverImageUrl: res.coverImageUrl });
  };

  return campaignDetail.assetsByGroup
    ? (
      <Container maxWidth={false} className={classes.campaignDetail}>
        <div className={classes.campaignInfo}>
          <CampaignInfoField campaign={campaignDetail} field="title" onSave={({ id, title }) => { updateCampaignTitle(id, title); }} />
          <Divider orientation="vertical" className={classes.campaignInfoDivider} />
          <CampaignInfoField campaign={campaignDetail} field="coverImage" onSave={onSaveCoverImage} />
        </div>
        <Divider orientation="horizontal" />
        <TabContext value={tabValue}>
          <div>
            <Typography variant="h2" className={classes.contentTitle}>
              Upload Assets
            </Typography>
            <Typography variant="h3">
              Assets size and duration rules:
            </Typography>
            <Typography variant="body1">
              Ratio Requirement: Recommend to ratio in 3:4 and 4:5
            </Typography>
            <Typography variant="body1">
              Size Requirement: Still image less than 100MB ,
              Video duration less than 15 Sec, size less than 100MB
            </Typography>
          </div>
          <TabList onChange={handleTabSwitch} className={classes.tabList}>
            {campaignDetail.assetsByGroup.map((group) => (
              <Tab
                className={group.groupName === tabValue ? classes.selectedTab : classes.tab}
                label={`${ASSET_GROUP[group.groupName]} (${group.assets.length})`}
                value={group.groupName}
                key={group.groupName}
              />
            ))}
          </TabList>

          {campaignDetail.assetsByGroup.map((group) => (
            <TabPanel
              className={classes.tabPanel}
              value={group.groupName}
              key={`${group.groupName}-panel`}
            >
              <AssetGroup campaignId={campaignId} group={group} />
            </TabPanel>
          ))}
        </TabContext>
      </Container>
    ) : (
      <></>
    );
}
