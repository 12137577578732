/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { emitter, EMITTER_KEY, load } from '../../../util';
import { UPLOAD } from '../../../constants';
import { getUploadSignedUrl, createThumbnail } from '../campaign.services';


const useStyles = makeStyles({
  input: {
    display: 'none',
  },
  uploadButton: {
    maxWidth: '200px',
    flex: 1,
    borderRadius: '20px',
    width: '100%',
  },
  arrowUpward: {
    marginRight: '14px',
    paddingBottom: '5px',
    fontSize: '1.75rem',
  },
});

export default function UploadButton(props) {
  const classes = useStyles();

  const getSignedUrl = async (filename, prefix = '') => {
    const result = await getUploadSignedUrl(props.id, prefix + props.assetGroupName, filename);

    if (result.signedUrl) {
      const url = new URL(result.signedUrl);
      return {
        pathname: url.pathname,
        url: (process.env.NODE_ENV !== 'production') ? result.signedUrl.replace(`${url.protocol}//${url.host}`, '/s3upload') : result.signedUrl,
      };
    }

    return '';
  };

  const doUpload = async (file) => {
    const signedUrl = await getSignedUrl(file.name);
    if (signedUrl) {
      emitter.emit(EMITTER_KEY.UPLOAD_INPROGRESS);
      await axios.put(
        signedUrl.url,
        file,
        {
          headers: {
            'Content-Type': file.type,
            'x-amz-acl': 'public-read',
          },
        },
      );

      return createThumbnail(signedUrl.pathname.substring(1));
    }

    return Promise.reject(new Error(`------->get signed url error:${file.name}`));
  };
  const p = () =>{
    return new Promise((resolve, reject) => {
      let intTime  = setInterval(() => {

        if (window.ffmpeg.isLoaded()) {

          resolve("settimeout");
          clearInterval(intTime);

        }
      }, 50);
    });
  };

  // const f = async function() {
  //   //const that = this
  //   //console.log('setInterval')
  //   //const x =await this.p();
  //   //console.log(x)
  //   //setTimeout(that.f, 2000)
  // };
  /* eslint-disable no-undef */
  const onFileChange = async (event) => {
    const files = [].slice.call(event.target.files);

    if (props.onUpload) {
      props.onUpload(files);
      return;
    }
    if (!files.length || files.length > UPLOAD.LIMIT_COUNT) {
      emitter.emit(EMITTER_KEY.UPLOAD_ERROR, `Violation of upload quantity. Could not more than ${UPLOAD.LIMIT_COUNT} once time.`);
      return;
    }






    load.open(true,"1/"+files.length);
    await p();


    const results = [];
    let returnMsg = 'Upload success';
    let uploadSuccess = true;

    //files.forEach(async (file, index) => {

    for (let index=0 ;index<files.length;index++) {
       let file = files[index];
      let { name } = file;
      if (file.size > UPLOAD.LIMIT_SIZE) {
        returnMsg += (name+'Filt the files more then 100MB.');
        uploadSuccess = false;
        continue;
        //return;
      }
//       console.log("=-------------------");
 //console.log(file.type);
     //
      let outName = name;
      let outType = file.type;
      if(file.type == 'image/png'){
        outType = 'image/jpg';
        outName = name +".jpg";
      }

      await window.ffmpeg.FS('writeFile', name, await window.fetchFile(files[index]));

      console.log('Start transcoding')
      await window.ffmpeg.run('-i', name,  outName);

      console.log('Complete transcoding') ;

      let data = await window.ffmpeg.FS('readFile', outName);


     // let blob =　new Blob([data.buffer], { type: 'image/jpg' })


      let newfiles = await new window.File([new Blob([data.buffer], {type: outType})],  outName, {type:outType}) ;// 转成file
      await window.ffmpeg.FS('unlink',  outName);

      results.push(doUpload(newfiles, index));
      load.open(true," 文件上传中"+(index+1)+"/"+files.length);

      //try{
        /* eslint-disable no-undef */
      // console.log(chrome.tabs)
      //   chrome.tabs.query({ active: true, currentWindow: true }, function(tabs) {
      //     /* eslint-disable no-undef */
      //     chrome.tabs.executeScript(
      //       tabs[0].id,
      //       { code: `CompressImage("${file}")` },function (result) {
      //         console.log(result)
      //         results.push(doUpload(result, index));
      //       });
      //   });
      // }catch (e) {

      //}



     // await

    }

    //});

    emitter.emit(EMITTER_KEY.UPLOAD_START, { total: files.length });
    try {
      await Promise.all(results);
      emitter.emit(EMITTER_KEY.UPLOAD_END, { returnMsg, success: uploadSuccess });
    } catch (err) {
      console.error(err);

      emitter.emit(EMITTER_KEY.UPLOAD_END, { returnMsg, success: false });
    }

    load.close();
  };

  return (
    <div>
      <input
        accept="image/*, video/*"
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
        onChange={onFileChange}
      />
      <label htmlFor="contained-button-file">
        <Button
          className={classes.uploadButton}
          color="primary"
          variant="outlined"
          component="span"
        >
          <ArrowUpwardIcon className={classes.arrowUpward} />
          <Typography variant="button">Upload New</Typography>
        </Button>
      </label>
    </div>
  );
}

UploadButton.propTypes = {
  onUpload: PropTypes.func,
  id: PropTypes.string.isRequired,
  assetGroupName: PropTypes.string.isRequired,
};

UploadButton.defaultProps = {
  onUpload: null,
};
